import React from 'react'
import { Grid, Divider, useMediaQuery } from '@mui/material'
import { MtBusinessLogo, MtConsumerLogo } from '../logos'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    '& > *': {
      marginBottom: spacing(2)
    }
  },
  divider: {
    height: 50,
    '&.MuiDivider-root': {
      backgroundColor: palette.grey.shade[50]
    },
    '&.MuiDivider-flexItem': {
      alignSelf: 'auto'
    }
  }
}))

const Logos = props => {
  const classes = useStyles(props)
  const narrow = useMediaQuery(({ breakpoints }) => breakpoints.down(325))

  return (
    <Grid
      container
      direction={narrow ? 'column' : 'row'}
      alignItems='center'
      justifyContent={narrow ? 'center' : 'space-around'}
      wrap='nowrap'
      className={classes.root}
    >
      <Grid item>
        <MtBusinessLogo />
      </Grid>
      {!narrow && (
        <Grid item>
          <Divider orientation='vertical' flexItem className={classes.divider} />
        </Grid>
      )}
      <Grid item>
        <MtConsumerLogo />
      </Grid>
    </Grid>
  )
}

export default Logos

import { createSlice } from '@reduxjs/toolkit'
import name from './name'

const initialState = {
  skillBites: []
}

const shortDescriptionPlaceholder = ''

export const skillBite = createSlice({
  name,
  initialState,
  reducers: {
    setSkillBites: (state, { payload }) => {
      state.skillBites = payload.map(item => ({ ...item, shortDescription: item.shortDescription || shortDescriptionPlaceholder }))
    },
    setSkillBiteSubscription: (state, { payload }) => {
      const { subscription } = payload
      state.skillBites = state.skillBites.map(skillBite => {
        if (skillBite.id === subscription.skillBiteId) {
          skillBite.subscription = subscription
        }
        return skillBite
      })
    }
  }
})

export const {
  setSkillBites,
  setSkillBiteSubscription
} = skillBite.actions

export default skillBite.reducer

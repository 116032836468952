import { ActionButton } from '@emerald-works-nova/components'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { ColourSchemes } from './campaign-util'

const useStyles = makeStyles(() => ({
  bannerBtn: {
    backgroundColor: ({ btn }) => btn,
    border: 'none',
    color: ({ btnText }) => btnText
  }
}))

export const CampaignActionBtn = ({ campaign, ...props }) => {
  const { ctaText, landingPage, colourScheme } = campaign
  const { btn, btnText } = ColourSchemes[colourScheme] || {}
  const classes = useStyles({ btn, btnText })

  const handleClick = () => window.open(landingPage, 'blank')

  return (
    <ActionButton variant='primary' dataTest='banner-button' className={classes.bannerBtn} onClick={handleClick} {...props}>
      {ctaText}
    </ActionButton>
  )
}

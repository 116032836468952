import React from 'react'
import { Hub } from '@aws-amplify/core'
import { ApplicationAuth, useTenant } from '@emerald-works-nova/auth'
import { EventBusProvider } from '@emerald-works/react-event-bus-client'
import CssBaseline from '@mui/material/CssBaseline'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { FreeAccountCtaModal } from './components'
import AlertProvider from './components/alert/provider'
import Favicon from './components/favicon'
import { SEOProvider } from './components/seo-context'
import { useCleanCookies } from './hooks'
import { EventContextProvider } from './hooks/useEventContext'
import usePageTracking from './hooks/useGA'
import { userCookiesGenerated } from './reducers/user/event-bus'
import { routes } from './routes'
import { authErrorSlice } from './reducers'
import { Outlet, useLocation } from 'react-router'
import useSessionInactivity from './hooks/useSessionInactivity'

const SessionInactivity = () => {
  useSessionInactivity()
  return null
}

const App = () => {
  const tenant = useTenant()
  const dispatch = useDispatch()
  const location = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  usePageTracking()
  useCleanCookies()

  const trackEventBusState = (eventName, data) => {
    if (window.DD_RUM && typeof window.DD_RUM.addAction === 'function') {
      window.DD_RUM.addAction(eventName, data)
    } else {
      console.log('addAction is not available')
    }
  }

  // capture auth channel from @amplify/auth HUB and dispatch it to the store
  Hub.listen('auth', (data) => {
    // capture only signIn_failure events for now
    if (data?.payload?.event !== 'signIn_failure') return

    const { data: { message: errorMessage }, event, message } = data.payload
    dispatch(authErrorSlice.actions.setAuthError({ errorMessage, event, message }))
  })

  return (
    <>
      <Helmet>
        <meta name='prerender-status-code' content='200' />
      </Helmet>
      <ApplicationAuth
        loginRoute={routes.login.path}
        tenant={tenant}
        authOptions={{ authenticationFlowType: 'USER_PASSWORD_AUTH' }}
      >
        <EventBusProvider
          eventBusURL={tenant.eventBusUrl || process.env.REACT_APP_EVENT_BUS_URL}
          connectionParams={{
            tenantKey: tenant?.tenantKey || 'www'
          }}
          initialisers={[
            userCookiesGenerated
          ]}
          options={{
            socketIoUrl: tenant.eventBusBackupURL || (process.env.REACT_APP_EVENT_BUS_BACKUP_URL || 'https://socketio.ewnova.dev')
          }}
          useAuthProvider
          fetchUserProfile
          namespace='toolkit'
          onConnectionChange={status => trackEventBusState('onConnectionChange', status)}
          onOpen={data => trackEventBusState('onOpen', data)}
          onClose={data => trackEventBusState('onClose', data)}
          onReconnect={data => trackEventBusState('onReconnect', data)}
          onMaximum={data => trackEventBusState('onMaximum', data)}
          onError={data => trackEventBusState('onError', data)}
        >
          <EventContextProvider>
            <SEOProvider>
              <Favicon />
              <CssBaseline />
              <FreeAccountCtaModal />
              <AlertProvider>
                <SessionInactivity />
                <Outlet />
              </AlertProvider>
            </SEOProvider>
          </EventContextProvider>
        </EventBusProvider>
      </ApplicationAuth>
    </>
  )
}
export default App

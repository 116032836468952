import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Hidden,
  Typography
} from '@mui/material'
import LanguageMenu from './menu'
import { useStyles } from './style'

const TranslateDialog = ({
  open,
  onClose,
  language,
  allLanguages,
  onTranslate,
  defaultLanguage,
  handleSetDefaultLanguage
}) => {
  const classes = useStyles()

  const findMatchingLanguage = useCallback((value) => {
    if (!value) return null
    return allLanguages.find(language => language.value === value) || null
  }, [allLanguages])

  const [lang, setLang] = useState(findMatchingLanguage(defaultLanguage) || language)
  const [isDefault, setIsDefault] = useState(defaultLanguage && (lang.value === defaultLanguage))

  useEffect(() => {
    setIsDefault(defaultLanguage && (lang.value === defaultLanguage))
  }, [lang, defaultLanguage])

  const handleChange = value => {
    if (value) {
      setLang(value)
    }
  }

  const handleApply = () => {
    if (!lang?.value) {
      console.warning('Language is missing')
      return
    }
    console.log('handleApply [lang]', lang)
    onTranslate(lang.value)
    if (isDefault) {
      handleSetDefaultLanguage(lang.value)
    }
    onClose()
  }

  const handleDefaultChange = event => {
    setIsDefault(event.target.checked)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      aria-labelledby='dialog-title'
    >
      <DialogTitle id='dialog-title' className={classes.title}>
        Translate the article
      </DialogTitle>
      <DialogContent>
        <Hidden smDown>
          <Typography
            variant='subtitle2'
            color='textSecondary'
            className={classes.subtitle}
          >
            Please select one of the options below, or type the language you wish
          </Typography>
        </Hidden>
        <LanguageMenu
          value={lang}
          onChange={handleChange}
          languages={allLanguages}
        />
        <Box display='flex' alignItems='center' ml={0} mt={0}>
          <Checkbox
            style={{ opacity: 0.7 }}
            checked={!!isDefault}
            onChange={handleDefaultChange}
          />
          <Typography
            variant='subtitle2'
            style={{ opacity: 0.7 }}
          >
            Set as the default content language
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-test='translate-dialog-cancel'>
          Cancel
        </Button>
        <Button
          onClick={handleApply}
          color='primary'
          data-test='translate-dialog-apply'
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TranslateDialog

import React, { useCallback } from 'react'
import { useSnackbar } from 'notistack'
import { SnackbarItem, MsgAlert } from '../components/alert'

const useAlert = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const enqueueAlert = useCallback(
    (alert, opts) => {
      return enqueueSnackbar('', {
        content: key => (
          <SnackbarItem
            onClose={() => closeSnackbar(key)}
            data-test={`alert-${key}`}
          >
            {alert}
          </SnackbarItem>
        ),
        ...opts
      })
    },
    [enqueueSnackbar, closeSnackbar]
  )

  const enqueueMsg = useCallback(
    (msg, severity) =>
      enqueueAlert(<MsgAlert msg={msg} severity={severity} />),
    [enqueueAlert]
  )

  return { enqueueAlert, enqueueMsg }
}

export default useAlert

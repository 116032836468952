import { useTenant } from '@emerald-works-nova/auth'
import React from 'react'
import { Helmet } from 'react-helmet'

const DEFAULT_FAVICON = 'favicon.ico'
const ENTERPRISE_FAVICON = 'MTfB_favicon.png'

const Favicon = () => {
  const tenant = useTenant()

  const favName = tenant?.isPublicTenant ? DEFAULT_FAVICON : ENTERPRISE_FAVICON
  const favHref = `${process.env.PUBLIC_URL}/${favName}`

  return (
    <Helmet>
      <link rel='icon' href={favHref} />
    </Helmet>
  )
}

export default Favicon

export const ColourSchemes = {
  'dark-blue': {
    value: 'dark-blue',
    bg: '#2B3B4C',
    text: '#FFF',
    btn: '#E08330',
    btnText: '#FFF'
  },
  'dark-red': {
    value: 'dark-ed',
    bg: '#A32F20',
    text: '#FFF',
    btn: '#FFF',
    btnText: '#636469'
  },
  yellow: {
    value: 'yellow',
    bg: '#D9A638',
    text: '#FFF',
    btn: '#2B3B4C',
    btnText: '#FFF'
  },
  orange: {
    value: 'orange',
    bg: '#E08330',
    text: '#FFF',
    btn: '#2B3B4C',
    btnText: '#FFF'
  },
  'light-blue': {
    value: 'light-blue',
    bg: '#B2CCEB',
    text: '#18191A',
    btn: '#2B3B4C',
    btnText: '#FFF'
  },
  light: {
    value: 'light',
    bg: '#ECEAE6',
    text: '#18191A',
    btn: '#2B3B4C',
    btnText: '#FFF'
  }
}

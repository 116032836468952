import * as colors from '@mui/material/colors'
import {
  accent,
  grey,
  alert,
  success,
  publicTenantSecondary,
  secondaryGrey
} from './custom-colors'

// This is the base palette that will be used to build the theme.
// `palette` param option will either override or add values to
// the palette property of theme object that is inject for the entire application.

// Add new properties as needed and all application components will have the
// new proprity available in the `theme.palette` object

// For more information about each property and what it means:
// https://material-ui.com/customization/palette/

export default {
  primary: {
    // light: '#9AD1C6',
    // main: '#3D9688',
    main: '#0070BD'
    // dark: '#0D7E6A'
  },
  secondary: {
    light: colors.pink.A200,
    main: colors.pink.A400,
    dark: colors.pink.A700
  },
  accent: {
    light: accent[5],
    main: accent[50],
    dark: accent[60],
    shade: { ...accent }
  },
  grey: {
    shade: { ...grey }
  },
  alert: {
    light: alert[30],
    main: alert[50],
    dark: alert[60],
    shade: { ...alert }
  },
  error: {
    main: '#B00020'
    // light: colors.red[300],
    // main: colors.red[500],
    // dark: colors.red[700]
  },
  warning: {
    light: colors.orange[300],
    main: colors.orange[500],
    dark: colors.orange[700]
  },
  info: {
    light: colors.blue[300],
    main: colors.blue[500],
    dark: colors.blue[700]
  },
  success: {
    main: '#31AA91',
    shade: { ...success }
    // light: colors.green[300],
    // main: colors.green[500],
    // dark: colors.green[700]
  },
  publicTenantSecondary: {
    light: publicTenantSecondary[5],
    main: publicTenantSecondary[50],
    dark: publicTenantSecondary[60],
    shade: { ...publicTenantSecondary }
  },
  secondaryGrey: {
    light: secondaryGrey[5],
    main: secondaryGrey[50],
    dark: secondaryGrey[80],
    shade: { ...secondaryGrey }
  },
  type: 'light',
  contrastThreshold: 3,
  tonalOffset: 0.2,
  // The colors used to style the text.
  text: {
    // The most important text.
    primary: 'rgba(0, 0, 0, 0.87)',
    // Secondary text.
    secondary: 'rgba(0, 0, 0, 0.54)',
    // Disabled text have even lower visual prominence.
    disabled: 'rgba(0, 0, 0, 0.38)',
    // Text hints.
    hint: 'rgba(0, 0, 0, 0.38)',
    // Custom color
    customColor: '#76ff03',
    // Extra information
    info: '#1869A5'
  },
  // The color used to divide different elements.
  divider: 'rgba(0, 0, 0, 0.12)',
  // The background colors used to style the surfaces.
  // Consistency between these values is important.
  background: {
    paper: colors.common.white,
    // default: colors.grey[50]
    default: colors.common.white
  },
  // The colors used to style the action elements.
  action: {
    // The color of an active action like an icon button.
    active: 'rgba(0, 0, 0, 0.54)',
    // The color of an hovered action.
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    // The color of a selected action.
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    // The color of a disabled action.
    disabled: 'rgba(0, 0, 0, 0.26)',
    // The background color of a disabled action.
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
    disabledOpacity: 0.38,
    focus: 'rgba(0, 0, 0, 0.12)',
    focusOpacity: 0.12,
    activatedOpacity: 0.12
  },
  white: {
    main: '#FFF',
    off: '#FDFDFD'
  },
  comment: {
    background: '#F0F2F5',
    textColor: 'rgba(0, 0, 0, 0.6)',
    emojiButtonColor: '#9E9E9E',
    textAreaBorder: '1px solid #D9D9D9',
    sorterColor: 'rgba(0, 0, 0, 0.12)',
    loadMoreBackgroundColor: '#EFEFEF'
  },
  button: {
    primary: {
      background: '#0070BD',
      text: '#FFF',
      border: '#0070BD'
    },
    secondary: {
      background: 'transparent',
      text: '#0070BD',
      border: 'rgba(0, 0, 0, 0.12)'
    },
    disabled: {
      border: 'rgba(0, 0, 0, 0.12)',
      text: 'rgba(0, 0, 0, 0.38)',
      background: 'rgba(0, 0, 0, 0.12)'
    }
  }
}

/* eslint-disable multiline-ternary */

import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Button, Grid, Hidden, useMediaQuery } from '@mui/material'
import { ChevronLeft, MailTwoTone } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { categoryUrl, contentUrl } from '../../helpers/url'
import { campaignSlice, categorySlice, exploreSlice, newsletterSlice } from '../../reducers'
import { ALL_CONSUMER, BROWSER } from '../../services/cognito/userGroups'
import { ActionButton } from '../action-buttons'
import { ExploreMenuCampaignBanner } from '../campaign/explore-menu-banner'
import FullPageDialog from '../full-page-dialog'
import Hide from '../hide'
import { UserGroupPolicy } from '../user-group-policy'
import CategoryView from './category-view'
import Discover from './discover'
import MarketingLinks, { useStyles as useMarketingLinksStyles } from './marketing-links'
import Menu from './menu'
import NewestReleasesList from './newest-releases'
import { useStyles } from './style'

const ExploreMenu = ({ open, onClose }) => {
  const classes = useStyles()
  const linkClasses = useMarketingLinksStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [currentGroup, setCurrentGroup] = useState()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const groups = useSelector(exploreSlice.selectors.selectGroups)
  const categories = useSelector(exploreSlice.selectors.selectCategories)
  const popular = useSelector(exploreSlice.selectors.selectPopular)
  const trending = useSelector(exploreSlice.selectors.selectTrending)
  const recommended = useSelector(exploreSlice.selectors.selectRecommended)
  const related = useSelector(exploreSlice.selectors.selectRelated)
  const campaign = useSelector(campaignSlice.selectors.campaignData)
  const isPopoverOpen = useSelector(newsletterSlice.selectors.selectIsNewsletterPopoverOpen)
  const [trackNewsletterOpenPopover] = useEvent([{ eventName: 'newsletterOpenPopover' }])

  const [getCategoryHierarchy, getDiscover] = useEvent([
    exploreSlice.eventBus.getCategoryHierarchy,
    exploreSlice.eventBus.getDiscover
  ])

  // fetch heirarchy and discover on load for seo (links in html)
  useEventsOnViewLoad(() => {
    getCategoryHierarchy.trigger({})
    // temporarily use content search to fill discover until available
    getDiscover.trigger({})
  }, [getCategoryHierarchy, getDiscover])

  const mobile = useMediaQuery(({ breakpoints }) => breakpoints.down('sm'))

  const handleEnter = () => setCurrentGroup()
  const handleClose = () => onClose()

  const handleGroupSelect = currentGroup => {
    if (currentGroup.noExpand) {
      // TODO: navigate with search criteria when no categories
      navigate(`/${currentGroup.sk}`)
      handleClose()
    } else {
      setCurrentGroup(currentGroup)
    }
  }

  const handleCategorySelect = category => {
    const path = [category, currentGroup]
    dispatch(categorySlice.actions.setCategory({
      category,
      pathToRoot: path
    }))
    navigate(categoryUrl(category, path))
    handleClose()
  }

  const handleArticleSelect = article => {
    navigate(contentUrl(article, 'explore'))
    handleClose()
  }

  const handleNewsletterClick = () => {
    handleClose()
    if (!isPopoverOpen) {
      trackNewsletterOpenPopover.trigger({
        from: 'appbar'
      })
    }
    dispatch(newsletterSlice.actions.toggleNewsletterPopover())
  }

  useEffect(() => {
    setMobileMenuOpen(!!currentGroup)
  }, [currentGroup])

  // render both panels for SEO as they contain crawlable links
  const rightPanel = (
    <Grid item className={classes.rightPanel}>
      <Hide hidden={!!currentGroup}>
        <Discover
          popular={popular}
          trending={trending}
          recommended={recommended}
          onSelect={handleArticleSelect}
          onClose={handleClose}
        />
      </Hide>
      <Hide hidden={!currentGroup}>
        <CategoryView
          group={currentGroup}
          categories={categories}
          related={related}
          recommended={recommended}
          onCategorySelect={handleCategorySelect}
          onArticleSelect={handleArticleSelect}
          onClose={handleClose}
        />
      </Hide>
    </Grid>
  )

  return (
    <FullPageDialog
      open={open}
      onClose={handleClose}
      keepMounted
      TransitionProps={{ onEnter: handleEnter }}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='stretch'
        className={classes.container}
      >
        <Grid item className={classes.groups}>
          <Menu
            items={groups ? groups.filter(group => group.name) : []}
            onClose={handleClose}
            onChange={handleGroupSelect}
            value={currentGroup}
            sideMenu
          />
          <NewestReleasesList onClose={onClose} />
          <UserGroupPolicy
            accessGroup={[BROWSER]}
            componentAllow={
              <ActionButton
                size={mobile ? 'xl' : 'l'}
                label='Newsletter'
                variant='link'
                onClick={handleNewsletterClick}
                startIcon={<MailTwoTone />}
                className={linkClasses.link}
              />
            }
          />
          <UserGroupPolicy
            accessGroup={ALL_CONSUMER}
            componentAllow={<MarketingLinks />}
          />
          <Hidden lgUp>
            <ExploreMenuCampaignBanner campaign={campaign} vertical />
          </Hidden>
        </Grid>

        {mobile ? (
          <FullPageDialog
            open={mobileMenuOpen && open}
            onClose={handleClose}
            TransitionProps={{ direction: open ? 'left' : 'right' }}
          >
            <Button
              onClick={() => setCurrentGroup()}
              variant='text'
              size='large'
              className={classes.menuButton}
              startIcon={<ChevronLeft />}
              data-test='nav-main-menu'
            >
              MAIN MENU
            </Button>
            {rightPanel}
          </FullPageDialog>
        ) : (
          rightPanel
        )}
      </Grid>
    </FullPageDialog>
  )
}

export default ExploreMenu

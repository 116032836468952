import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card,
  CardMedia,
  CardActionArea,
  Collapse,
  Typography,
  Grid,
  Skeleton
} from '@mui/material'
import {
  LockTwoTone,
  Language
} from '@mui/icons-material'
import LayersIcon from '@mui/icons-material/Layers'

import IconTag from '../icon-tag'
import clsx from 'clsx'
import { Stack } from './stack'
import MtLogo from '../logos/mindtools_logo_primary_rgb.svg'
import { useStyles } from './style'
import ContentTypeBadge from '../content-type-badge'
import Link from '../link'
import { contentUrl } from '../../helpers/url'
import TimerIcon from '@mui/icons-material/Timer'

/** A generic card that can be customised to display different content. */
const GeneralCard = ({
  content,
  stack,
  onSelect,
  className,
  overrides = {},
  actions,
  expand = false,
  children,
  componentProps,
  testId,
  defaultImage,
  right = false,
  fullWidth,
  isPlaylist,
  playlistItemCount = 0,
  link,
  onMouseEnter,
  onMouseLeave
}) => {
  const classes = useStyles({ content, stack, onSelect, className, overrides, actions, expand, children, componentProps, testId, defaultImage, right, fullWidth, isPlaylist, playlistItemCount, link })

  let contentImage = defaultImage || MtLogo
  let contentTime

  if (content?.image?.imgUrl) {
    contentImage = content?.image?.imgUrl + 'fit=crop&w=367&h=202'
  }

  if (content?.readTime >= 0) {
    contentTime = content?.readTime + ' mins'
  }

  return (
    <Box
      className={clsx(classes.root, className, overrides.root)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-test={testId || `card-${content?.friendlyUrl}'}`}
    >
      <Stack number={stack ?? 0}>
        <Card variant='elevation' classes={{ root: clsx(classes.card, overrides.card) }}>
          {!(fullWidth && right) &&
            (content
              ? (
                <CardMedia
                  onClick={onSelect}
                  image={contentImage}
                  title={content?.image?.altText}
                  className={classes.image}
                  data-testid={`card-image-${testId}`}
                  data-test={`card-image-${content?.friendlyUrl}`}
                />
                )
              : (
                <Skeleton variant='rectangular' className={classes.image} />
                ))}
          <Box className={clsx(classes.summary, overrides.summary)}>
            <CardActionArea
              onClick={onSelect}
              disabled={!content}
              disableRipple
              classes={{
                focusHighlight: classes.actionAreaHighlighted
              }}
              data-testid={`card-action-area-${testId}`}
              data-test={`card-action-area-${content?.friendlyUrl ??
                'loading'}`}
            >
              <Grid
                container
                direction='row'
                alignItems='center'
                wrap='nowrap'
                justifyContent='space-between'
                className={classes.header}
                data-test={`card-header-${content?.friendlyUrl}'}`}
              >
                {content && (
                  <>
                    <Grid
                      xs
                      item
                      container
                      direction='row'
                      alignItems='center'
                      justifyContent='space-evenly'
                      wrap='nowrap'
                      className={classes.info}
                    >
                      {!isPlaylist && (
                        <Grid item xs>
                          <ContentTypeBadge
                            primaryType={content?.primaryType}
                            dataTest={`card-content-type-${content?.friendlyUrl}'}`}
                            classes={{ root: clsx(overrides.cardContentType) }}
                          />
                        </Grid>
                      )}
                      {content?.primaryType[0] === 'playlist' && (
                        <>
                          {!isPlaylist && (
                            <Grid item xs>
                              <Typography align='center'>&middot;</Typography>
                            </Grid>
                          )}
                          <Grid item xs>
                            <IconTag
                              icon={
                                content.visibility === 'private'
                                  ? (
                                    <LockTwoTone />
                                    )
                                  : (
                                    <Language />
                                    )
                              }
                              text={
                                content.visibility === 'private'
                                  ? 'Private'
                                  : 'Public'
                              }
                            />
                          </Grid>

                        </>
                      )}
                    </Grid>

                    <Grid item xs={7}>
                      {isPlaylist
                        ? (
                          <Typography
                            variant='body1'
                            className={clsx(classes.time, overrides.time)}
                            noWrap
                            align='right'
                            display='flex'
                            data-test={`card-time-${content?.friendlyUrl}'}`}
                          >
                            <LayersIcon /> {playlistItemCount} Items
                            {contentTime
                              ? (
                                <>&nbsp;&middot;&nbsp;<TimerIcon />{contentTime}</>
                                )
                              : (<Skeleton width='60px' height='40px' />)}
                          </Typography>

                          )
                        : (
                          <Typography
                            variant='body1'
                            className={clsx(classes.time, overrides.time)}
                            noWrap
                            align='right'
                            data-test={`card-time-${content?.friendlyUrl}'}`}
                          >
                            {contentTime}
                          </Typography>
                          )}
                    </Grid>

                  </>
                )}
              </Grid>
              <Typography
                component={componentProps?.title ?? 'h3'}
                className={classes.title}
                data-test={`card-title-${content?.friendlyUrl}'}`}
              >
                {link ?? (
                  <Link
                    to={content ? contentUrl(content) : ''}
                  >
                    {content?.title ?? <Skeleton width='80%' />}
                  </Link>
                )}

              </Typography>

              <Collapse in={expand}>
                <Typography
                  variant='body2'
                  color='textSecondary'
                  className={classes.subtitle}
                  data-test={`card-subtitle-${content?.friendlyUrl}'}`}
                >
                  {content?.subtitle?.html ?? (
                    <>
                      <Skeleton width='100%' />
                      <Skeleton width='70%' />
                    </>
                  )}
                </Typography>
              </Collapse>
            </CardActionArea>

            {children}

            <Grid
              container
              className={clsx(classes.actions, overrides.actions)}
              justifyContent='space-between'
              wrap='nowrap'
              spacing={1}
              data-testid={`card-actions-${testId}`}
              data-test={`card-actions-${content?.friendlyUrl}'}`}
            >
              {content
                ? (
                  <>
                    <Grid
                      container
                      item
                      justifyContent='flex-start'
                      spacing={1}
                      wrap='nowrap'
                      data-test={`card-left-actions-${content?.friendlyUrl}'}`}
                    >
                      {actions?.leftActions}
                    </Grid>
                    <Grid
                      container
                      item
                      justifyContent='flex-end'
                      spacing={1}
                      wrap='nowrap'
                      data-test={`card-right-actions-${content?.friendlyUrl}'}`}
                    >
                      {actions?.rightActions}
                    </Grid>
                  </>
                  )
                : (
                  <Box ml={0.5} width='100%'>
                    <Skeleton variant='rectangular' width='40%' />
                  </Box>
                  )}
            </Grid>
          </Box>
          {fullWidth &&
            right &&
            (content
              ? (
                <CardMedia
                  onClick={onSelect}
                  image={contentImage}
                  title={content?.image?.altText}
                  className={classes.image}
                  data-testid={`card-image-${testId}`}
                  data-test={`card-image-${content?.friendlyUrl}`}
                />
                )
              : (
                <Skeleton variant='rectangular' className={classes.image} />
                ))}
        </Card>
      </Stack>
    </Box>
  )
}

export default GeneralCard

GeneralCard.propTypes = {
  /** Content to be displayed on card */
  content: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([false, undefined])
  ]),
  /** Buttons the user can click on to perform card related actions */
  actions: PropTypes.exact({
    leftActions: PropTypes.element,
    rightActions: PropTypes.element
  }),
  /** Should the card be expanded (show description)?  */
  expand: PropTypes.bool,
  /** Number of stacked borders the card should show  */
  stack: PropTypes.number,
  /** Child elements that appear below the card summary and action buttons  */
  children: PropTypes.node,
  /** Callback function fired when the card is selected  */
  onSelect: PropTypes.func
}

/* eslint-disable indent */
import React from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useFormFactor } from '../../hooks'
import { useTenant } from '@emerald-works-nova/auth'

const useStyles = makeStyles(({ palette, spacing }) => ({
  legal: {
    color: palette.grey.shade[80],
    fontSize: '0.875rem'
  },
  consumerContainer: {
    paddingBottom: spacing(5),
    backgroundColor: palette.grey.shade[5]
  },
  container: {
    paddingBottom: spacing(5)
  }
}))

const mobileFormat = [
  `\u00a9 Mind Tools Ltd ${new Date().getFullYear()}.`,
  'All rights reserved.',
  '\u201cMind Tools\u201d is a registered trademark',
  'of Mind Tools Ltd.'
]

const tabletFormat = [
  mobileFormat.slice(0, 2).join(' '),
  mobileFormat.slice(2, 4).join(' ')
]

const largeFormat = [mobileFormat.join(' ')]

const Legal = () => {
  const classes = useStyles()
  const { isMobile, isSmallTablet } = useFormFactor()

  const text = isMobile
    ? mobileFormat
    : isSmallTablet
    ? tabletFormat
    : largeFormat

  const tenant = useTenant()
  const isConsumer = React.useMemo(() => tenant?.tenantKey === 'www', [tenant])

  return (
    <div className={isConsumer ? classes.consumerContainer : classes.container}>
      {text.map((line, index) => (
        <Typography
          align='center'
          key={index}
          className={classes.legal}
          data-test='footer-legal'
        >
          {line}
        </Typography>
      ))}
    </div>
  )
}

export default Legal

import React, { useEffect } from 'react'
// import google-adsense.css
import './google_ad.css'

const GoogleAd = ({ location = 'not set' }) => {
  // initialise this advert on component load
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (error) {
      console.debug('*** Google adsense component error', error)
    }
  }, [])

  if (process.env.REACT_APP_GOOGLE_ADSENSE_USE_PLACEHOLDERS !== 'true') {
    return (
      <div className='google_adsense_container'>
        <ins
          className={`adsbygoogle ${location}`}
          data-ad-client={process.env.REACT_APP_GOOGLE_ADSENSE_CLIENT}
          data-ad-slot={process.env.REACT_APP_GOOGLE_ADSENSE_SLOT}
          data-ad-format='auto'
          data-full-width-responsive='true'
        />
      </div>
    )
  } else {
    return (
      <div className='google_adsense_container_placeholder'>
        <ins className={`adsbygoogle ${location}`}>
          PLACEHOLDER GOOGLE ADSENSE ADVERT<br />
          {location}
        </ins>
      </div>
    )
  }
}

export default GoogleAd

import React from 'react'
import Alert from './alert'
import AlertTitle from './title'
import { capitalize } from 'lodash'

const MsgAlert = ({
  severity,
  msg,
  title = capitalize(severity),
  ...props
}) => {
  return (
    <Alert severity={severity} showClose {...props}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {msg}
    </Alert>
  )
}

export default MsgAlert
